import config from 'config';

export const formStates = {
  DRAFT: 1,
  SENT: 2,
  REQUEST_MORE_INFO: 3,
  PROPOSED: 4,
  WAITING_FOR_APPROVAL: 5,
  DECIDED: 6,
  WAITING_FOR_DELIVERY: 7,
  PRELIMINARY: 8,
  WAITING_CUSTOMER_TO_APPROVE: 9,
  APPROVED: 10,
  REJECTED: 11,
  ENDED: 12,
  DELETED: 13
};

export const formStateCodes = {
  1: 'DRAFT',
  2: 'SENT',
  3: 'REQUEST_MORE_INFO',
  4: 'PROPOSED',
  5: 'WAITING_FOR_APPROVAL',
  6: 'DECIDED',
  7: 'WAITING_FOR_DELIVERY',
  8: 'PRELIMINARY',
  9: 'WAITING_CUSTOMER_TO_APPROVE',
  10: 'APPROVED',
  11: 'REJECTED',
  12: 'ENDED'
};

export const requestConstants = {
  credentials: 'same-origin',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'SEMAFetch',
    'Content-Type': 'application/json'
  }
};

export const formTypes = {
  EIDENTIFICATION: 'eIdentification',
  DATAEXCHANGELAYER: 'dataexchangelayer',
  EAUTHORIZATION: 'eauthorization',
  MESSAGES: 'messages',
  SERVICECATALOGUE: 'servicecatalogue'
};

export const roles = {
  INTERMEDIARY: 'intermediary',
  OWNORGANISATION: 'ownOrganisation'
};

export const maxFiles = 5;

export const maxSize = 5000000; // 5MB - per file?

export const proposalStyles = {
  INTERMEDIARY: 'INTERMEDIARY',
  OWN: 'OWN'
};

export const userPermitOfSuomiServices = locale =>
  config?.generalRegistrationFiles?.userPermit?.[locale || 'fi'] || '';

export const dataexchangelayerLicense = locale =>
  config?.dataExchangeLayerRegistrationForm?.userPermit?.[locale || 'fi'] || '';

export const eidentificationLicense = locale =>
  config?.tunnistusRegistrationForm?.userPermit?.[locale || 'fi'] || '';

export const eauthorizationLicense = locale =>
  config?.eAuthorizationRegistrationForm?.userPermit?.[locale || 'fi'] || '';

export const eauthorizationLogging = locale =>
  config?.eAuthorizationRegistrationForm?.logging?.[locale || 'fi'] || '';

export const messagesLicense = locale =>
  config?.messagesRegistrationForm?.userPermit?.[locale || 'fi'] || '';

export const servicecatalogueLicense = locale =>
  config?.serviceCatalogueRegistrationForm?.userPermit?.[locale || 'fi'] || '';

export const servicecatalogueEditorialPolicy = locale =>
  config?.serviceCatalogueRegistrationForm?.editorialPolicy?.[locale || 'fi'] ||
  '';
