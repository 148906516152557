import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  redirect,
  redirectToRoot,
  redirectWildcard,
  redirectOut
} from '../utils';
import config from 'config';
import getAuthRoutes from './AuthRoutes';
import getHome from './Home';
import { externalRedirects } from './externalRedirects';

const routes = externalRedirects.map(({ from, to }) => redirectOut(from, to));

/**
 * Plain root routing of the app (React Router 3)
 *
 * Needed routes (and sub-route sets) are added dynamically.
 * Routes needing their own reducers will pass the store.
 * e.g. require('./Dashboard').default(store)
 *
 * Note:
 * Webpack is chunking (sub-)routes to their own dynamically
 * loaded .js bundle files if the async require.ensure or newer import are used.
 *
 * Some of the "always used" route code should be included in the main app bundle
 * (see Search and NotFound).
 *
 * @param store Redux store
 * @param AppWrapper Obviously the wrapping App React Component
 * @param browserLang Default lang string
 * @returns {*[]} Routes object to react-router 3
 */
// #FIXME: browserLang is broken, translations.currentLang in app state remains null and LanguageSwitcher component does not update it
export const createRoutes = (store, AppWrapper, browserLang) => {
  const allRoutes = () => [
    // Provide store for async reducers and middleware
    // require('./Chat').default,
    require('./ContentPages').default(store, browserLang),
    require('./Dashboard').default(store),
    require('./Feedback').default,
    require('./StaticArticles').ArticleRoadmap(store),
    require('./StaticArticles').ArticleReleaseNews(store),
    require('./IdentificationAdmin').default(store, browserLang),
    // three following routes are related to eidentification prerequisite form
    require('./Formss').tunnistusAccessForm(store),
    require('./Formss').acceptForms(store),
    require('./FormsDigi').tunnistusAccessForm(store),
    require('./FormsDigi').serviceBusCertificateForm(store, browserLang),
    require('./FormsDigi').serviceCatalogueAccessForm(store),
    require('./Organisation').default(store, browserLang),
    require('./ROVAAdmin').default(store, browserLang),
    require('./KVHAdmin').default(store, browserLang),
    require('./Search').default,
    // require('./Notifications').default(store, browserLang),
    require('./SignIn').default(browserLang),
    require('./PAHAAdmin').default,
    require('./Admin').default(store, browserLang),
    //require('./Admin').ManageNotifications(store, browserLang),
    require('./Support').default(store, browserLang),
    require('./Topical').default(store, browserLang),
    require('./UserProfile').default(store, browserLang),
    require('./UserRegistration').default(store),
    require('./UserRegistration').RegisterInvitedUser(store),
    require('./UserRegisterError').default,
    require('./IntermediaryForms').IdentificationForm(store, browserLang),
    require('./IntermediaryForms').ApproveApplication(store, browserLang),
    require('./IntermediaryForms').ServiceCatalogueForm(store, browserLang),
    require('./IntermediaryForms').MessagesForm(store, browserLang),
    require('./IntermediaryForms').DataExchangeLayerForm(store, browserLang),
    require('./IntermediaryForms').eAuthorizationForm(store, browserLang),
    require('./EnrollmentForms').RestrictedDataForm(store, browserLang),
    require('./OwnApplications').default(store, browserLang),
    require('./NotFound').default // Keep this last!
  ];

  return [
    ...routes,
    redirectOut(`/${browserLang}/raportit`, config.reports.url),
    redirect(
      `/${browserLang}/viestit-hae-kayttolupaa/uusi`,
      `/${browserLang}/valitoimija/messages`
    ),
    redirect(
      `/${browserLang}/valtuudet-hae-kayttolupaa/uusi`,
      `/${browserLang}/valitoimija/eauthorization`
    ),
    redirect(
      `/${browserLang}/palvelutietovaranto-hae-kayttolupaa/uusi`,
      `/${browserLang}/valitoimija/servicecatalogue`
    ),
    redirect(
      `/${browserLang}/tunnistus-hae-kayttolupaa/uusi`,
      `/${browserLang}/valitoimija/eidentification`
    ),
    redirect(
      `/${browserLang}/palveluvayla-hae-kayttolupaa/uusi`,
      `/${browserLang}/valitoimija/dataexchangelayer`
    ),
    redirect(
      `/${browserLang}/valitoimija/dataexchangelayer`,
      `/${browserLang}/valitoimija/dataexchangelayer/uusi`
    ),
    redirect(
      `/${browserLang}/valitoimija/eidentification`,
      `/${browserLang}/valitoimija/eidentification/uusi`
    ),
    redirect(
      `/${browserLang}/valitoimija/servicecatalogue`,
      `/${browserLang}/valitoimija/servicecatalogue/uusi`
    ),
    redirect(
      `/${browserLang}/valitoimija/eauthorization`,
      `/${browserLang}/valitoimija/eauthorization/uusi`
    ),
    redirect(
      `/${browserLang}/valitoimija/messages`,
      `/${browserLang}/valitoimija/messages/uusi`
    ),
    redirectToRoot(store, browserLang),
    redirect('/dashboard', `/${browserLang}/dashboard`),
    redirect(
      '/rekisteroityminen/tiedoksi',
      `/${browserLang}/rekisteroityminen/tiedoksi`
    ),
    redirect(
      '/rekisteroityminen/rekisterointi-valmis/org',
      `/${browserLang}/rekisteroityminen/rekisterointi-valmis/org`
    ),
    redirect(
      '/rekisteroityminen/odottaa-kutsua',
      `/${browserLang}/rekisteroityminen/odottaa-kutsua`
    ),
    redirectWildcard(`/fi/tilastot/**`, `/tilastot/`, `/raportit/`),
    redirectWildcard(`/en/tilastot/**`, `/tilastot/`, `/raportit/`),
    redirectWildcard(`/sv/tilastot/**`, `/tilastot/`, `/raportit/`),

    ...getAuthRoutes(store, browserLang),
    require('./SahaPrintApplication').default(store),
    {
      path: '/:lang',
      staticName: true,
      name: (
        <span>
          <FormattedMessage id="home.title" />
        </span>
      ),

      component: AppWrapper,
      indexRoute: getHome(store),
      getChildRoutes(location, next) {
        next(null, allRoutes());
      }
    }
  ];
};

export default createRoutes;
