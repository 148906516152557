import { asyncConnect } from 'redux-connect';
import {
  sendSuccessNotification,
  sendErrorNotification
} from 'utils/notifications';
import FormView from './FormView';
import { changeOrg } from '../../../../modules/auth';

const mapActionCreators = {
  sendSuccessNotification,
  sendErrorNotification,
  changeOrg
};

const mapStateToProps = state => ({
  activeOrgId: state.auth.user?.activeOrganizationId,
  user: state.auth.user
});

export default asyncConnect([], mapStateToProps, mapActionCreators)(FormView);
