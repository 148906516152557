import React from 'react';
import { requireStrongAuth } from 'modules/auth/utils';

import { RestrictedData } from './forms';

const authenticate = store => requireStrongAuth(store.getState().auth);

/**
 * Restricted data (Suojattu data)
 */
export const RestrictedDataForm = store => ({
  path: 'enrollment/restricteddata/:id',
  onEnter: authenticate(store),
  components: {
    content: props => <RestrictedData store={store} {...props} />
  }
});
