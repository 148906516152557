import { ApplicationsListView } from '../views/ApplicationsListView';
import {
  getForms,
  updateFilter,
  updateFormState,
  updateQuery,
  updateSorting
} from '../modules/module_OwnApplications';
import { sendErrorNotification } from 'utils/notifications';
import { asyncConnect } from 'redux-connect';

const mapActionCreator = {
  updateFilter,
  updateQuery,
  updateSorting,
  updateFormState,
  getForms
};

const mapStateToProps = state => ({
  isFetching: state.ownApplicationsView.isFetching,
  allForms: state.ownApplicationsView.forms || [],
  filters: state.ownApplicationsView.filters,
  query: state.ownApplicationsView.query,
  sortingColumns: state.ownApplicationsView.sortingColumns,
  showIntermediaryApplications:
    state.ownApplicationsView.showIntermediaryApplications,
  activeOrganizationId: state.auth.user && state.auth.user.activeOrganizationId,
  organisation: state.auth.user.organizations.find(
    org => org.id === state.auth.user.activeOrganizationId
  )
});

export default asyncConnect(
  [
    {
      promise: ({ store: { dispatch }, helpers: { store } }) => {
        const userState = store.getState().auth.user;
        const orgId = userState.activeOrganizationId;

        Promise.all([
          dispatch(getForms(orgId), store).then(result => {
            if (result.error) {
              dispatch(
                sendErrorNotification(
                  'forms.notifications.own_forms_loading_error_title',
                  ''
                )
              );
            }
          })
        ]);
      }
    }
  ],
  mapStateToProps,
  mapActionCreator
)(ApplicationsListView);
