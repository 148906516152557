import React from 'react';

import { serviceTypes } from '../../constants';

import { postData } from '../../utils/api';
import FormContainer from '../../components/Form/FormContainer';

const RestrictedData = () => {
  return (
    <FormContainer
      serviceType={serviceTypes.RESTRICTEDDATA}
      postData={() => postData({ serviceType: 'RESTRICTEDDATA' })}
    />
  );
};

export default RestrictedData;
