export const externalRedirects = [
  {
    from: '/fi/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/sivut/palveluhallinnasta',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluhallinta'
  },
  {
    from: '/en/sivut/palveluhallinnasta',
    to: 'https://kehittajille.suomi.fi/services/service-managament'
  },
  {
    from: '/sv/sivut/palveluhallinnasta',
    to: 'https://kehittajille.suomi.fi/tjanster/serviceadministrationen'
  },
  {
    from: '/fi/sivut/palveluhallinnasta/yleisesittely',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/mika-on-palveluhallinta'
  },
  {
    from: '/sv/sivut/palveluhallinnasta/yleisesittely',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/vad-ar-suomi-fi-serviceadministration'
  },
  {
    from: '/en/sivut/palveluhallinnasta/yleisesittely',
    to:
      'https://kehittajille.suomi.fi/services/service-managament/what-is-suomi-fi-service-management'
  },
  {
    from: '/fi/tuki/artikkelit/6422e2e8cbc29c045ac5c551',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/mika-on-palveluhallinta/valitoimijamallin-kayttoonottaminen-palveluhallinnassa'
  },
  {
    from: '/fi/tuki/artikkelit/642ace5e9763bc08324531be',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/mika-on-palveluhallinta/valitoimijamallin-kayttoonottaminen-palveluhallinnassa/nain-hyvaksyt-valitoimijan-tekeman-kayttolupahakemuksen'
  },
  {
    from: '/fi/tuki/artikkelit/59ddebdea5fcf1007e2c0f60',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/nain-rekisteroidyt-palveluhallintaan'
  },
  {
    from: '/sv/tuki/artikkelit/59ddebdea5fcf1007e2c0f60',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/sa-har-registrerar-du-dig-i-suomi-fi-serviceadministrationen'
  },
  {
    from: '/fi/tuki/artikkelit/5b38d6ee9a7d9e00121b6af9',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/mika-on-palveluhallinta/mita-eroa-on-palveluhallinnan-ja-digi-ja-vaestotietoviraston-verkkoasioinnilla'
  },
  {
    from: '/sv/tuki/artikkelit/5b38d6ee9a7d9e00121b6af9',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/vad-ar-serviceadministration/skillnaden-mellan-serviceadministrationen-och-e-tjansten-for-mbd'
  },
  {
    from: '/fi/tuki/ukk/5b38d291786130511b2f9866',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/mika-on-palveluhallinta/mita-eroa-on-palveluhallinnan-ja-digi-ja-vaestotietoviraston-verkkoasioinnilla'
  },
  {
    from: '/sv/tuki/ukk/5b38d291786130511b2f9866',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/vad-ar-serviceadministration/skillnaden-mellan-serviceadministrationen-och-e-tjansten-for-mbd'
  },
  {
    from: '/fi/tuki/artikkelit/5b38d620a0d5ad6f941bd6d3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/kayttajahallinta/mita-organisaation-paakayttaja-tekee'
  },
  {
    from: '/sv/tuki/artikkelit/5b38d620a0d5ad6f941bd6d3',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/anvandaradministration/vad-gor-huvudanvandaren-i-serviceadministrationen'
  },
  {
    from: '/fi/tuki/artikkelit/59ddf026d5baef0047be6db0',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/kayttajahallinta/uuden-kayttajan-kutsuminen'
  },
  {
    from: '/sv/tuki/artikkelit/59ddf026d5baef0047be6db0',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/anvandaradministration/att-bjuda-in-en-ny-anvandare-till-din-egen-organisation'
  },
  {
    from: '/fi/tuki/artikkelit/5b38d620a0d5ad6f941bd6d3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluhallinta/kayttajahallinta'
  },
  {
    from: '/sv/tuki/artikkelit/59ddee7381d2f300670b9597',
    to:
      'https://kehittajille.suomi.fi/tjanster/serviceadministrationen/anvandaradministration'
  },
  {
    from: '/fi/sivut/palvelutietovaranto/esittely',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/mika-on-palvelutietovaranto'
  },
  {
    from: '/sv/sivut/palvelutietovaranto/esittely',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/en/sivut/palvelutietovaranto/esittely',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from:
      '/fi/sivut/palvelutietovaranto/kayttoonotto/kayttoonottoon-valmistautuminen',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/palvelutietovarannon-kayttoonotto'
  },
  {
    from:
      '/sv/sivut/palvelutietovaranto/kayttoonotto/kayttoonottoon-valmistautuminen',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from:
      '/en/sivut/palvelutietovaranto/kayttoonotto/kayttoonottoon-valmistautuminen',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/sivut/palvelutietovaranto/kayttoonotto/tietojen-hyodyntaminen',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen'
  },
  {
    from: '/sv/sivut/palvelutietovaranto/kayttoonotto/tietojen-hyodyntaminen',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/en/sivut/palvelutietovaranto/kayttoonotto/tietojen-hyodyntaminen',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/tuki/palvelut/palvelutietovaranto',
    to: 'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto'
  },
  {
    from: '/sv/tuki/palvelut/palvelutietovaranto',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/en/tuki/palvelut/palvelutietovaranto',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/tuki/artikkelit/63c8f1c8b097ee0acf40b8cb',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/palvelutietovaranto/mika-on-palvelutietovaranto/kayton-tuki-ja-koulutukset'
  },
  {
    from: '/sv/tuki/artikkelit/63c8f1c8b097ee0acf40b8cb',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5aabe9e32503b4004f2bc0f1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/mika-on-palvelutietovaranto/kayton-tuki-ja-koulutukset/palvelutietovarannon-koulutukset'
  },
  {
    from: '\t/sv/tuki/artikkelit/5aabe9e32503b4004f2bc0f1',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5aabde522503b4004f2bc0f0',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen'
  },
  {
    from: '/sv/tuki/artikkelit/5aabde522503b4004f2bc0f0',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/65ae0c57eedab70141669b33',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/palvelun-kuvaamisen-ohjeet/erityisohjeet-kunnille/tyovoimapalveluiden-kuvaaminen'
  },
  {
    from: '\t/sv/tuki/artikkelit/65ae0c57eedab70141669b33',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/59dd0ec5d5baef0047be6daf',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/palvelutietovarannon-kayttoonotto/nain-otan-in-rajapinnan-kayttoon'
  },
  {
    from: '\t/sv/tuki/artikkelit/59dd0ec5d5baef0047be6daf',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '\t/en/tuki/artikkelit/59dd0ec5d5baef0047be6daf',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/tuki/artikkelit/5b6d74409ef01764b2dbd297',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/tyoskentelyn-organisointi/ptv-paakayttajan-tehtavat'
  },
  {
    from: '\t/sv/tuki/artikkelit/5b6d74409ef01764b2dbd297',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/59636d9e777f4800014a1121',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/tyoskentelyn-organisointi'
  },
  {
    from: '/sv/tuki/artikkelit/59636d9e777f4800014a1121',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/en/tuki/artikkelit/59636d9e777f4800014a1121',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/tuki/artikkelit/59dd061062040f005c904237',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/tyoskentelyn-organisointi'
  },
  {
    from: '/sv/tuki/artikkelit/59dd061062040f005c904237',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7aac71e916cc06dca4d7d1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/palvelun-kuvaamisen-ohjeet/mita-ovat-pohjakuvaukset'
  },
  {
    from: '/sv/tuki/artikkelit/5a7aac71e916cc06dca4d7d1',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/59de47198c7a88003225437c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/palvelun-kuvaamisen-ohjeet'
  },
  {
    from: '/sv/tuki/artikkelit/59de47198c7a88003225437c',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7ab24de916cc06dca4d7d4',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/asiointikanavien-kuvaamisen-ohjeet'
  },
  {
    from: '/sv/tuki/artikkelit/5a7ab24de916cc06dca4d7d4',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7ab7546b6a0463a88240a7',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/organisaatio/miten-kuvaan-organisaation'
  },
  {
    from: '\t/sv/tuki/artikkelit/5a7ab7546b6a0463a88240a7',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7ab3f99ea47311bfd5999a',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/palvelutietovaranto/sisallon-tuottaminen/liitosten-tekemisen-ohjeet'
  },
  {
    from: '/sv/tuki/artikkelit/5a7ab3f99ea47311bfd5999a',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7ac0e5e916cc06dca4d7d6',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen/tekninen-dokumentaatio-integraation-toteuttajalle/lisatietoa-out-integraation-toteuttajalle'
  },
  {
    from: '/sv/tuki/artikkelit/5a7ac0e5e916cc06dca4d7d6',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5ecb9ec28a565900f8adaaf3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen'
  },
  {
    from: '/sv/tuki/artikkelit/5ecb9ec28a565900f8adaaf3',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/59523fcf777f4800014a10f8',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen/tekninen-dokumentaatio-integraation-toteuttajalle'
  },
  {
    from: '/sv/tuki/artikkelit/59523fcf777f4800014a10f8',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5d2e31a7a9199d00926ac9a8',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen/tekninen-dokumentaatio-integraation-toteuttajalle'
  },
  {
    from: '/sv/tuki/artikkelit/5d2e31a7a9199d00926ac9a8',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5a7ac0381d35d85524630e18',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen/tekninen-dokumentaatio-integraation-toteuttajalle'
  },
  {
    from: '/sv/tuki/artikkelit/5a7ac0381d35d85524630e18',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/tuki/artikkelit/5d2e31a7a9199d00926ac9a8',
    to:
      'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto/ptv-tietojen-hyodyntaminen/tekninen-dokumentaatio-integraation-toteuttajalle'
  },
  {
    from: '/sv/tuki/artikkelit/5d2e31a7a9199d00926ac9a8',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/fi/sivut/valtuudet/palvelukuvaus/palvelun-kuvaus',
    to: 'https://kehittajille.suomi.fi/palvelut/valtuudet/palvelukuvaus'
  },
  {
    from: '/fi/sivut/valtuudet/palvelukuvaus/palvelutasot',
    to:
      '\thttps://kehittajille.suomi.fi/palvelut/valtuudet/palvelukuvaus/palvelutasot'
  },
  {
    from: '/fi/sivut/valtuudet/palvelukuvaus/tietoturva',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/palvelukuvaus/tietoturva'
  },
  {
    from: '/fi/sivut/valtuudet/palvelukuvaus/tietosuoja',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/palvelukuvaus/tietosuoja'
  },
  {
    from: '/fi/sivut/valtuudet/kayttoonotto/prosessi',
    to: 'https://kehittajille.suomi.fi/palvelut/valtuudet/kayttoonotto'
  },
  {
    from: '/fi/tuki/artikkelit/5a7819ce89c5c77cb05252ea',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/kayttoonotto/valtuusasioiden-maaritysohje'
  },
  {
    from: '/fi/tuki/artikkelit/5e8c163951706e5e2ff57b3e',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/kayttoonotto/malli-asiakasohje-asiointipalveluille'
  },
  {
    from: '/fi/tuki/artikkelit/630c8e32caf01e00c28724e4',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/kayttoonotto/yllapito/palveluntarjoajan-vaihtuminen-ja-palvelun-kayton-paattaminen'
  },
  {
    from: '/fi/sivut/valtuudet/tekninen-yleiskuvaus',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio'
  },
  {
    from: '/fi/tuki/artikkelit/641956a5b45ce40764a6f3bc',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/kayttotapaukset/kolme-tapaa-hyodyntaa-authorization-rest-rajapintaa'
  },
  {
    from: '/fi/tuki/artikkelit/62a73b42ab78bb02686b54ad',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/kayttotapaukset/huollonjakotoiminnallisuus'
  },
  {
    from: '/fi/tuki/artikkelit/5c423d09019391001b18733e',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/liityntatavan-ja-rajapintojen-valinta'
  },
  {
    from: '/fi/tuki/artikkelit/592d774503f6d100018db5dd',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/web-api-rajapinta-ja-valintakayttoliittyma'
  },
  {
    from: '/fi/tuki/artikkelit/5a781dc75cb4f10dde9735e4',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/web-api-yhteys-kehittajan-ohje'
  },
  {
    from: '/fi/tuki/artikkelit/60adfcb69066100570dd5a40',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/rajapintakyselyiden-testausohje'
  },
  {
    from: '/fi/tuki/artikkelit/592d5b7d03f6d100018db5d9',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/valtuustarkistuskyselyn-rajapintakuvaus-henkilon-asioidessa-yrityksen-puolesta'
  },
  {
    from: '/fi/tuki/artikkelit/5b7ae30f4ab254003df11408',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/valtuustarkistuskyselyn-rajapintakuvaus-yrityksen-asioidessa-toisen-yrityksen-puolesta-organizationalmandates-kysely'
  },
  {
    from: '/fi/tuki/artikkelit/5fa12b3f067c1f00caacadf0',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/rajapintakuvaukset/valtuustarkistuskyselyn-rajapintakuvaus-yrityksen-asioidessa-henkilon-puolesta-organizationalpersonmandates-kysely'
  },
  {
    from: '/fi/tuki/artikkelit/592d5fad03f6d100018db5da',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/saantomoottorikuvaukset/saantomoottori-henkilon-puolesta-asioinnissa-delegate-authorization'
  },
  {
    from: '/fi/tuki/artikkelit/592d67c203f6d100018db5dc',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/saantomoottorikuvaukset/saantomoottori-yrityksen-puolesta-asioinnissa-organizationalroles'
  },
  {
    from: '/fi/tuki/artikkelit/5f80461bcf1db90825ccef67',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/saantomoottorikuvaukset/saantomoottori-yrityksen-asioidessa-henkilon-puolesta-organizationalpersonmandates'
  },
  {
    from: '/fi/tuki/artikkelit/634cf5b193d64d00a0e43ef0',
    to:
      'https://kehittajille.suomi.fi/palvelut/valtuudet/tekninen-dokumentaatio/lokitusvaatimukset'
  },
  {
    from: '/fi/sivut/valtuudet/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/palvelut/valtuudet/ota-yhteytta'
  },
  {
    from: '/fi/tuki/artikkelit/66b320349a8f94001395116c',
    to:
      'https://kehittajille.suomi.fi/palvelut/tunnistus/tekninen-kayttoonotto/metatiedot/tunnistus-idp-metadatojen-allekirjoitusvarmenteet-20242026'
  },
  {
    from: '/sv/tuki/artikkelit/66b320349a8f94001395116c',
    to:
      'https://kehittajille.suomi.fi/tjanster/identifikation/teknisk-implementering/metadata/suomi-fi-identifikation-idp-metadata-signeringscertifikat-20242026'
  },
  {
    from: '/en/tuki/artikkelit/66b320349a8f94001395116c',
    to:
      'https://kehittajille.suomi.fi/services/e-identification/how-to-implement-the-technical-setup-of-the-identification-service/metadata/e-identification-idp-metadata-signing-certificates-2024-2026'
  },
  {
    from: '/fi/sivut/viestit/kayttoonotto/vaiheet',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit/kayttoonotto'
  },
  {
    from: '/fi/sivut/viestit/kayttoonotto/tkj-palvelu',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/kayttoonotto/tulostus-kuoritus-ja-jakelupalvelun-kayttoonotto'
  },
  {
    from: '/fi/sivut/viestit/kayttoonotto/it-toimittajille',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/kayttoonotto/viestien-kayttoonotto-valitoimijana'
  },
  {
    from: '/fi/sivut/viestit/tekninen-yleiskuvaus/viestit-palvelu',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus'
  },
  {
    from: '/fi/sivut/viestit/tekninen-yleiskuvaus/tkj-palvelu',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/toiminnallisuudet/paperipostin-lahettaminen'
  },
  {
    from: '/fi/sivut/viestit/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit/yhteystiedot'
  },
  {
    from: '/fi/tuki/artikkelit/5c5c42b9ab050e00502c97c9',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus'
  },
  {
    from: '/fi/tuki/artikkelit/5c71aeaf46e7410049a6d8ad',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6231a43ee21596007ed2b3fd',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6231a819e014bf0100455b70',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6231a6a012fec8010e16ba28',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6231a96912fec8010e16ba29',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6231aa32e014bf0100455b71',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/5c672c8b7c5387185de7b818',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/5c730624a54d3f001bb4880b',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/ws-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/5c5f43064eb74d31b6f36c60',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/vanhat-rajapinnat/sftp'
  },
  {
    from: '/fi/tuki/artikkelit/5c5ec9478298c61c598f605e',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/tekninen-kuvaus/post-rajapinta'
  },
  {
    from: '/fi/tuki/artikkelit/6437967dd3659b08cfb800dc',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/kayttoonotto/valmisohjelmistot-ja-viestien-kayttoonotto'
  },
  {
    from: '/fi/tuki/artikkelit/5c5df418b2a5861c87bcb262',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/yllapito/tekninen-yllapito'
  },
  {
    from: '/fi/tuki/artikkelit/6228a1e403fcb5008c16ae47',
    to:
      'https://kehittajille.suomi.fi/palvelut/laatutyokalut/laatutyokalujen-kaytto/api-tunnuksen-luominen-laatutyokaluissa'
  },
  {
    from: '/fi/tuki/artikkelit/61570048f80814001787d100',
    to:
      'https://kehittajille.suomi.fi/palvelut/laatutyokalut/laatutyokalujen-kaytto/tietojen-tarkastelu-ja-vertailu'
  },
  {
    from: '/fi/tuki/artikkelit/615eea9faf64e6001159f279',
    to:
      'https://kehittajille.suomi.fi/palvelut/laatutyokalut/laatutyokalujen-kaytto/kayttoastetyokalun-kaytto'
  },
  {
    from: '/fi/tuki/artikkelit/6156f3a7ed29e9006800a073',
    to:
      'https://kehittajille.suomi.fi/palvelut/laatutyokalut/laatutyokalujen-kaytto/tietojen-tarkastelu-ja-vertailu'
  },
  {
    from: '/sv/tuki/artikkelit/6156f3a7ed29e9006800a073',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/sv/tuki/artikkelit/615eea9faf64e6001159f279',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/sv/tuki/artikkelit/614da1c647cb710fa3421482',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/sv/tuki/artikkelit/614d9c49107c37103af10782',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/en/tuki/artikkelit/615eea9faf64e6001159f279',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/en/tuki/artikkelit/6156f3a7ed29e9006800a073',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/en/tuki/artikkelit/614da1c647cb710fa3421482',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/en/tuki/artikkelit/614d9c49107c37103af10782',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/en/tuki/artikkelit/61375b6e813e370ea4954e36',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/fi/sivut/maksut/esittely',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut/palvelukuvaus/esittely'
  },
  {
    from: '/sv/sivut/maksut/esittely',
    to:
      'https://kehittajille.suomi.fi/tjanster/betalningar/beskrivning-av-tjansten/presentation'
  },
  {
    from: '/fi/sivut/maksut/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut/yhteystiedot'
  },
  {
    from: '/sv/sivut/maksut/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/tjanster/betalningar/kontaktinformation'
  },
  {
    from: '/fi/sivut/maksut/tekninen-yleiskuvaus',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut/tekninen-kuvaus'
  },
  {
    from: '/sv/sivut/maksut/tekninen-yleiskuvaus',
    to: 'https://kehittajille.suomi.fi/tjanster/betalningar/teknisk-beskrivning'
  },
  {
    from: '/fi/sivut/maksut/kayttoonotto',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut/kayttoonotto'
  },
  {
    from: '/sv/sivut/maksut/kayttoonotto',
    to: 'https://kehittajille.suomi.fi/tjanster/betalningar/ibruktagande'
  },
  {
    from: '/fi/sivut/palveluhallinnasta/palvelut',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/sivut/palveluhallinnasta/palvelut',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/sivut/palveluhallinnasta/palvelut',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/ajankohtaista/uutiset/66fe3c8a4e383400138bcfff',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/muutos-palveluhallintaan-suomi-fi-palveluiden-ohjeet-ja-uutiset-siirtyvat-suomi-fi-kehittajille-sivustolle-30-10'
  },
  {
    from: '/sv/ajankohtaista/uutiset/66fe3c8a4e383400138bcfff',
    to:
      'https://kehittajille.suomi.fi/aktuell/andring-i-serviceadministrationen-suomi-fi-tjansternas-anvisningar-och-nyheter-overfors-till-webbplatsen-suomi-fi-for-tjansteutvecklare-30-oktober'
  },
  {
    from: '/en/ajankohtaista/uutiset/66fe3c8a4e383400138bcfff',
    to:
      'https://kehittajille.suomi.fi/topical/a-change-will-take-place-in-service-management-the-instructions-and-news-of-suomi-fi-services-will-move-to-the-suomi-fi-for-service-developers-website-on-30-october'
  },
  {
    from: '/fi/ajankohtaista/uutiset',
    to: 'https://kehittajille.suomi.fi/ajankohtaista'
  },
  {
    from: '/sv/ajankohtaista/uutiset',
    to: 'https://kehittajille.suomi.fi/aktuell'
  },
  {
    from: '/en/ajankohtaista/uutiset',
    to: 'https://kehittajille.suomi.fi/topical'
  },
  {
    from: '/fi/ajankohtaista/tapahtumat',
    to: 'https://kehittajille.suomi.fi/tapahtumat'
  },
  {
    from: '/sv/ajankohtaista/tapahtumat',
    to: 'https://kehittajille.suomi.fi/evenemang'
  },
  {
    from: '/en/ajankohtaista/tapahtumat',
    to: 'https://kehittajille.suomi.fi/events'
  },
  {
    from: '/fi/ajankohtaista/hairiotiedotteet',
    to: 'https://kehittajille.suomi.fi/ajankohtaista?p=0&t=hairio'
  },
  {
    from: '/sv/ajankohtaista/hairiotiedotteet',
    to: 'https://kehittajille.suomi.fi/aktuell?p=0&t=hairio'
  },
  {
    from: '/en/ajankohtaista/hairiotiedotteet',
    to: 'https://kehittajille.suomi.fi/topical?p=0&t=hairio'
  },
  {
    from: '/fi/ajankohtaista/julkaisutiedotteet',
    to: 'https://kehittajille.suomi.fi/ajankohtaista?p=0&t=versio'
  },
  {
    from: '/sv/ajankohtaista/julkaisutiedotteet',
    to: 'https://kehittajille.suomi.fi/aktuell?p=0&t=versio'
  },
  {
    from: '/en/ajankohtaista/julkaisutiedotteet',
    to: 'https://kehittajille.suomi.fi/topical?p=0&t=versio'
  },
  {
    from: '/fi/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/tuki/etusivu',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/sivut/palveluvayla/esittely',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla'
  },
  {
    from: '/fi/sivut/palveluvayla/tuki/usein-kysyttya',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/5930108103f6d100018db612',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/5930105803f6d100018db611',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/593013b003f6d100018db622',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/60c1c255cf3e94085dd2981f',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi'
  },
  {
    from: '/fi/tuki/ukk/615ebe32ab5969009905a20c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi'
  },
  {
    from: '/fi/tuki/ukk/63a4139ee2fb4f063784430b',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi'
  },
  {
    from: '/fi/tuki/ukk/63a413be42a599066f9d642b',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi'
  },
  {
    from: '/fi/tuki/ukk/5c263bf3918376406916c993',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi'
  },
  {
    from: '/fi/tuki/ukk/5cae2261f6af894b9d3ed24b',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys'
  },
  {
    from: '/fi/tuki/ukk/593010b803f6d100018db613',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys'
  },
  {
    from: '/fi/tuki/ukk/5930127b03f6d100018db61d',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys'
  },
  {
    from: '/fi/tuki/ukk/5930125a03f6d100018db61c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys'
  },
  {
    from: '/fi/tuki/ukk/597b17b5a5c6450001c7f0fd',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin'
  },
  {
    from: '/fi/tuki/ukk/5930115103f6d100018db616',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin'
  },
  {
    from: '/fi/tuki/ukk/593010d903f6d100018db614',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin'
  },
  {
    from: '/fi/tuki/ukk/5a0ed6c3b3a6d000ce3c82be',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin'
  },
  {
    from: '/fi/tuki/ukk/597b162aa5c6450001c7f0fc',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin'
  },
  {
    from: '/fi/tuki/ukk/5930144b03f6d100018db624',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet'
  },
  {
    from: '/fi/tuki/ukk/61b886b42935d000bbf651ec',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet'
  },
  {
    from: '/fi/tuki/ukk/636a3752588139017dddeb8c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet'
  },
  {
    from: '/fi/tuki/ukk/5930147103f6d100018db625',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet'
  },
  {
    from: '/fi/tuki/ukk/597b2675a5c6450001c7f0fe',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ulkoistaminen'
  },
  {
    from: '/fi/tuki/ukk/5930149403f6d100018db626',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ulkoistaminen'
  },
  {
    from: '/fi/tuki/ukk/5a0ed578e7f4b90097f74868',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen'
  },
  {
    from: '/fi/tuki/ukk/5930138503f6d100018db621',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen'
  },
  {
    from: '/fi/tuki/ukk/593011f403f6d100018db61a',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/5930133a03f6d100018db61e',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/60b4e4cd129c8405df0632cd',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/5930119e03f6d100018db618',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/5c265a7f5d8e6f2ac1139ef6',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/usein-kysyttya-palveluvaylasta'
  },
  {
    from: '/fi/tuki/ukk/593011c603f6d100018db619',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen'
  },
  {
    from: '/fi/tuki/ukk/5930110403f6d100018db615',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen'
  },
  {
    from: '/fi/tuki/ukk/5c264bb6918376406916c994',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen'
  },
  {
    from: '/fi/sivut/palveluvayla/tuki/tukiartikkelit',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit'
  },
  {
    from: '/fi/tuki/artikkelit/5ef313c79a155e0139629cb5',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/alijarjestelman-ja-palveluiden-kuvaaminen-liityntakatalogiin'
  },
  {
    from: '/fi/tuki/artikkelit/5ef30460d3db3a015caa9b56',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/etsi-palveluita-liityntakatalogista'
  },
  {
    from: '/fi/tuki/artikkelit/63a1709c762221058aaef1d6',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/hae-yksittaisen-palvelun-kayttolupaa-liityntakatalogissa'
  },
  {
    from: '/fi/tuki/artikkelit/5ef498370270b1001d0bc9b5',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/liityntakatalogin-kayttajahallinta'
  },
  {
    from: '/fi/tuki/artikkelit/5ef3070ed3db3a015caa9b57',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/organisaation-tietojen-kuvaaminen-liityntakatalogiin'
  },
  {
    from: '/fi/tuki/artikkelit/61c314e87d4a4c0169d5f6e0',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukiartikkelit/liityntakatalogin-tukiartikkelit/palvelun-tekninen-kuvaus-liityntakatalogissa'
  },
  {
    from: '/fi/tuki/artikkelit/63a170c6e2fb4f063784430a',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntakatalogi/kayttolupahakemus-toiminnon-kayttoonottaminen-liityntakatalogissa'
  },
  {
    from: '/fi/tuki/artikkelit/592fc86103f6d100018db5fc',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet/juurivarmenteiden-asentaminen'
  },
  {
    from: '/fi/tuki/artikkelit/592bd1c103f6d100018db5c7',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet/liityntapalvelimen-vanhenevien-varmenteiden-uusiminen'
  },
  {
    from: '/fi/tuki/artikkelit/591eb25414bbb10001966fcf',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet/palveluvaylan-ymparistojen-varmenteet'
  },
  {
    from: '/fi/tuki/artikkelit/591da44c14bbb10001966fc9',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet/palveluvaylassa-kaytetyt-tunnisteet'
  },
  {
    from: '/fi/tuki/artikkelit/66fbd233cdfb02001add3085',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/varmenteet-ja-tunnisteet/palveluvaylan-automaattisesti-uusiutuvat-varmenteet'
  },
  {
    from: '/fi/tuki/artikkelit/63970aa927e2d8045ace0ef3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/alustapalvelimen-ja-liityntapalvelimen-nimeaminen'
  },
  {
    from: '/fi/tuki/artikkelit/592fbd1603f6d100018db5f8',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/asiakasjarjestelman-liittaminen-liityntapalvelimeen'
  },
  {
    from: '/fi/tuki/artikkelit/60a7e793663f73062a9ed87b',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-jakaminen-toisen-organisaation-kanssa'
  },
  {
    from: '/fi/tuki/artikkelit/59145e7b14bbb10001966f72',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-liittaminen-testi-tai-tuotantoymparistoon'
  },
  {
    from: '/fi/tuki/artikkelit/591da78714bbb10001966fca',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-suositellut-muistiasetukset-ja-ohjeet-niiden-muuttamiseen'
  },
  {
    from: '/fi/tuki/artikkelit/591ea0eb14bbb10001966fcd',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-tekniset-vaatimukset'
  },
  {
    from: '/fi/tuki/artikkelit/5922b8b914bbb10001966fd5',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelinohjelmiston-asennusohjeet-rhel-liityntapalvelimille'
  },
  {
    from: '/fi/tuki/artikkelit/5931237e03f6d100018db62b',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelinohjelmiston-asennusohjeet-ubuntu-liityntapalvelimille'
  },
  {
    from: '/fi/tuki/artikkelit/591ec90d14bbb10001966fd3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/metapalvelut'
  },
  {
    from: '/fi/tuki/artikkelit/592bdd9303f6d100018db5d1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/ohje-kayttoliittyman-avautumisongelmaan-kovennetuissa-ymparistoissa'
  },
  {
    from: '/fi/tuki/artikkelit/60a7d7f457a5ec0598c14e7a',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/palveluvaylan-rhel-tai-ubuntu-liityntapalvelin'
  },
  {
    from: '/fi/tuki/artikkelit/60cc4776d23066092b3bef27',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/palveluvaylan-x-road-toolkit-liityntapalvelimen-automaattiset-hallintaskriptit'
  },
  {
    from: '/fi/tuki/artikkelit/6034cfd7d23eec08851c0e8a',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/palveluvaylan-kontitettu-liityntapalvelin'
  },
  {
    from: '/fi/tuki/artikkelit/6034d6c098a47608bcbd447e',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/palveluvaylan-kontitetun-liityntapalvelimen-tekniset-tiedot'
  },
  {
    from: '/fi/tuki/artikkelit/59cdf0e3cdd262007192ac3e',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/palveluvaylan-testipalvelut'
  },
  {
    from: '/fi/tuki/artikkelit/5a0e9db6e3eb94008cd601e6',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/ulkoisen-kuormantasaajan-kayttaminen-palveluvaylassa'
  },
  {
    from: '/fi/tuki/artikkelit/591ac1e314bbb10001966f9c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/uuden-alijarjestelman-liittaminen-liityntapalvelimeen-ja-sen-poistaminen'
  },
  {
    from: '/fi/tuki/artikkelit/5f182709341bb40241fa9713',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/ota-suomi-fi-palveluvaylan-kautta-tarjottava-palvelu-kayttoon'
  },
  {
    from: '/fi/tuki/artikkelit/592e961103f6d100018db5ec',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/palvelun-lisaaminen-liityntapalvelimelle'
  },
  {
    from: '/fi/tuki/artikkelit/591ec60614bbb10001966fd0',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/palveluvaylan-sovitinpalvelu'
  },
  {
    from: '/fi/tuki/artikkelit/591d4cdf14bbb10001966fb4',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/suurten-liitetiedostojen-siirtaminen-palveluvaylassa'
  },
  {
    from: '/fi/tuki/artikkelit/592bf54a03f6d100018db5d4',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/x-road-tiedonsiirtoprotokolla'
  },
  {
    from: '/fi/tuki/artikkelit/6634a5e80eedf805c04e7e10',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/maarittele-palveluvaylan-palveluntarjoajana-asiakasorganisaatioidesi-liityntapalvelimilta-edellyttamasi-minimiohjelmistoversio'
  },
  {
    from: '/fi/tuki/artikkelit/656084b8e223760281017d3e',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/palvelut-tiedonsiirto-ja-sailytys/palveluvaylan-fi-dev-kehitysympariston-palvelinasennukset'
  },
  {
    from: '/fi/tuki/artikkelit/60a7eaee345f2e05c9c51a7f',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ulkoistaminen/palveluvaylaan-liittymisen-ulkoistaminen'
  },
  {
    from: '/fi/tuki/artikkelit/591ea2ab14bbb10001966fce',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen/liityntapalvelimen-valvonta'
  },
  {
    from: '/fi/tuki/artikkelit/595a08b3777f4800014a111c',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen/liityntapalvelinohjelmiston-paivitysohjeet-rhel-liityntapalvelimille'
  },
  {
    from: '/fi/tuki/artikkelit/5979a554777f4800014a116d',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/yllapitaminen/liityntapalvelinohjelmiston-paivitysohjeet-ubuntu-liityntapalvelimille'
  },
  {
    from: '/fi/tuki/artikkelit/60a93cfa36bd5a04ed4c9f55',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen/kayttolupahakemuksen-tayttaminen-palveluvaylaan'
  },
  {
    from: '/fi/tuki/artikkelit/5f929e368126af1032fc6297',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen/liittyminen-suomi-fi-palveluvaylan-kehitysymparistoon'
  },
  {
    from: '/fi/tuki/artikkelit/60a7663c715cfb04df1f16a3',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen/palveluvaylan-fi-dev-kehitysympariston-palvelinasennukset'
  },
  {
    from: '/fi/tuki/artikkelit/5ef49734fc908700178b8f51',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ymparistot-ja-testaaminen/palveluvaylan-ja-liityntakatalogin-ymparistot'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/teknisen-kaeyttoeoenoton-vaiheet',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto/1-tutustu-ja-suunnittele'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto/2-valitse-liityntapalvelinratkaisu'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto/3-liity-testiymparistoon'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto/4-testaa-testiymparistossa'
  },
  {
    from:
      '/fi/sivut/palveluvayla/tekninen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/tekninen-kayttoonotto/5-siirry-tuotantoymparistoon'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/hallinnollisen-kaeyttoeoenoton-vaiheet',
    to:
      '\xa0https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-2',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto/1-tutustu-ja-suunnittele'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-2',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto/2-valitse-liityntapalvelinratkaisu'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto/3-liity-testiymparistoon'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-2',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto/4-testaa-testiymparistossa'
  },
  {
    from:
      '/fi/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/hallinnollinen-kayttoonotto/5-siirry-tuotantoymparistoon'
  },
  {
    from: '/fi/sivut/palveluvayla/kayttoonotto/vaiheet',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto'
  },
  {
    from: '/fi/sivut/palveluvayla/kayttoonotto/liittyminen-kehitysymparistoon',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/liittyminen-kehitysymparistoon'
  },
  {
    from:
      '/fi/sivut/palveluvayla/kayttoonotto/yksittaeisen-palvelun-kaeyttoeoenotto',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/kayttoonotto/yksittaisen-palvelun-kayttoonotto'
  },
  {
    from: '/fi/sivut/palveluvayla/kayttoonotto/valitoimija',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ohjeita-valitoimijalle'
  },
  {
    from: '/fi/tuki/artikkelit/63f8ab85e0763400245f2c8f',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ohjeita-valitoimijalle/alijarjestelman-jakaminen-usean-organisaation-kesken'
  },
  {
    from: '/fi/tuki/artikkelit/63f8695a4d48f305a09ceac5',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ohjeita-valitoimijalle/palveluntarjoajan-vastuut-jaettua-alijarjestelmaa-kayttavan-organisaation-autentikoinnissa'
  },
  {
    from: '/fi/tuki/artikkelit/63f75e11d699ab0555d592f5',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/ohjeita-valitoimijalle/valitoimijan-vastuut-jaettua-alijarjestelmaa-kayttavan-organisaation-autentikoinnissa'
  },
  {
    from: '/fi/sivut/palveluvayla/yllapito/huolehdi-yllapidosta',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/yllapito'
  },
  {
    from: '/fi/sivut/palveluvayla/yllapito/hallinnollinen-yllapito',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/yllapito/hallinnollinen-yllapito'
  },
  {
    from: '/fi/sivut/palveluvayla/yllapito/tekninen-yllapito',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/yllapito/tekninen-yllapito'
  },
  {
    from: '/fi/sivut/palveluvayla/yllapito/versiopaivitykset',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/yllapito/versiopaivitykset'
  },
  {
    from: '/fi/sivut/palveluvayla/yllapito/Palvelun%20kayton%20paattaminen',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/yllapito/palvelun-kayton-paattaminen'
  },
  {
    from: '/fi/ajankohtaista/uutiset/664da2f98be4e00759012a3c',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-tuotantoympariston-keskuspalvelinymparisto-on-paivitetty-uuteen-x-road-7-4-2-versioon-22-05-2024'
  },
  {
    from: '/fi/ajankohtaista/uutiset/663490fe2931c90542c2c477',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-uusin-ohjelmistoversio-7-4-2-otetaan-kayttoon-tuotannossa'
  },
  {
    from: '/fi/ajankohtaista/uutiset/65570a46baab0701cac04a66',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-tuotantoympariston-fi-keskuspalvelinymparisto-on-paivitetty-uuteen-xroad-7-3-2-versioon-15-11-2023'
  },
  {
    from: '/fi/ajankohtaista/uutiset/62308ddef41f1d00f1b70e6e',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/paivita-suomi-fi-palveluvayla-uusi-versio-7-0-2-on-asennettu-tuotantoymparistoon-16-3-2022'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6193750bc17c97032417f87a',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-versio-7-0-0-on-julkaistu-30-11'
  },
  {
    from: '/fi/ajankohtaista/uutiset/606ee9f6865c8d006f8c621b',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-versio-6-26-0-on-julkaistu'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6005aaeee1619503a2d4c9b9',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-versio-6-25-0-on-julkaistu'
  },
  {
    from: '/fi/tuki/artikkelit/5f6898f8854e910b138b016a',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-versio-6-24-0-on-julkaistu-31-8-2020'
  },
  {
    from: '/fi/tuki/artikkelit/66fbcefa4e383400138bcffe',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-testiympariston-fi-test-keskuspalvelinymparisto-paivitetaan-x-roadin-7-5-1-versioon'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6089347a508cb802d99387e9',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/uusi-infotilaisuus-suomi-fi-palveluvaylan-uudistuksista-18-5-klo-14-15-30-tallenne-tilaisuudesta-lisatty'
  },
  {
    from: '/fi/ajankohtaista/uutiset/606eecfe865c8d006f8c621c',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/tervetuloa-kuulemaan-suomi-fi-palveluvaylan-uusista-ominaisuuksista-27-4-klo-14-15-30'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6193794da747da01e3387243',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/tervetuloa-kuulemaan-suomi-fi-palveluvaylan-uudesta-ohjelmistoversiosta-15-12-klo-8-309-tallenne-lisatty'
  },
  {
    from: '/fi/ajankohtaista/uutiset/666c2260fe4718087e61e5d9',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-aikaleimapalvelun-tsa-ei-jatkossa-enaa-hyvaksy-sha-1-tiivistealgoritmilla-tehtyja-aikaleimauspyyntoja'
  },
  {
    from: '/fi/ajankohtaista/uutiset/666020f516a8770870169d39',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/uusi-opas-neuvoo-suomi-fi-palveluvaylan-kayttoonotossa-ja-vtj-muutosrajapintaan-siirtymisessa'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6622219b85570f048c7b5d75',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-17-4-2024-tuotantoympariston-xroad-paivitys-peruutettu'
  },
  {
    from: '/fi/ajankohtaista/uutiset/65f17427d5a6ad01db2bb1fd',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-kehitysympariston-fi-dev-ja-testiympariston-fi-test-keskuspalvelinymparistot-on-paivitetty-uuteen-x-road-7-4-1-versioon-6-3-2024-ja-13-3-2024'
  },
  {
    from: '/fi/ajankohtaista/uutiset/65d5b1218d2de6001c68f0de',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/suomi-fi-palveluvaylan-uusin-ohjelmistoversio-7-4-1-otetaan-kayttoon'
  },
  {
    from: '/fi/sivut/palveluvayla/asiakastarinat',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/asiakastarinat'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6136075083ef890e820ed807',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/asiakastarina-suomi-fi-palveluvaylan-kautta-siun-soten-tiedot-liikkuvat-valtakunnallisiin-rekistereihin-vaivatta-ja-viiveetta'
  },
  {
    from: '/fi/ajankohtaista/uutiset/61716edc1c1c5601046ff3cf',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/turun-kaupunki-suomi-fi-palveluvayla-mahdollistaa-meille-ajantasaisten-tietojen-hakemisen-tietoturvallisesti'
  },
  {
    from: '/fi/ajankohtaista/uutiset/6204b87a85cfd1002b6aa422',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/asiakastarina-ketterampi-alku-yrittajyyteen-ja-opiskelijaetuihin-nain-op-on-hyodyntanyt-suomi-fi-palveluvaylaa'
  },
  {
    from: '/fi/ajankohtaista/uutiset/629458c525506c0114d7b22f',
    to:
      'https://kehittajille.suomi.fi/ajankohtaista/asiakastarina-sujuvammat-palvelut-ja-tehokkaampaa-viranomaisyhteistyota-verohallinto-saa-apua-suomi-fi-palveluvaylasta-myos-veropetosten-selvittelyyn'
  },
  {
    from: '/fi/sivut/palveluvayla/palvelukuvaus/palvelun-kuvaus',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/palvelukuvaus'
  },
  {
    from: '/fi/sivut/palveluvayla/palvelukuvaus/tekninen-yleiskuvaus',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/tekninen-yleiskuvaus'
  },
  {
    from: '/fi/sivut/palveluvayla/palvelukuvaus/palvelun-elinkaari',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/palvelun-elinkaari'
  },
  {
    from: '/fi/sivut/palveluvayla/palvelukuvaus/tietoturva',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/tietoturva'
  },
  {
    from: '/fi/sivut/palveluvayla/palvelukuvaus/tietosuoja',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/tietosuoja'
  },
  {
    from:
      '/fi/sivut/palveluvayla/palvelukuvaus/viestinta-ja-yhteistyomenettelyt',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/mika-on-palveluvayla/viestinta-ja-yhteistyomenettelyt'
  },
  {
    from: '/fi/sivut/palveluvayla/tuki/sanasto',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/sanasto'
  },
  {
    from: '/fi/sivut/palveluvayla/tuki/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla/ota-yhteytta'
  },
  {
    from: '/fi/tuki/artikkelit/6560838df089fc02b2a8110a',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-verkkoympariston-esimerkkiratkaisut-palvelun-hyodyntajalle'
  },
  {
    from: '/fi/tuki/artikkelit/650a9c3854e07f00c2477fd7',
    to:
      'https://kehittajille.suomi.fi/palvelut/palveluvayla/tukimateriaalit/liityntapalvelin/liityntapalvelimen-konfigurointi-x-road-toolkitilla'
  },
  {
    from: '/en/sivut/palveluvayla/palvelukuvaus/palvelun-kuvaus',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/service-presentation/service-description'
  },
  {
    from: '/en/sivut/palveluvayla/esittely',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/service-presentation'
  },
  {
    from: '/en/sivut/palveluvayla/tuki/usein-kysyttya',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/support-articles/data-exchange-layer-faq'
  },
  {
    from: '/en/sivut/palveluvayla/tuki/tukiartikkelit',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/support-articles'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/teknisen-kaeyttoeoenoton-vaiheet',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-1',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment/1-familiarise-and-plan'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-1',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment/2-select-a-security-server-solution'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment/3-join-the-test-environment'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-1',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment/4-test-in-the-test-environment'
  },
  {
    from:
      '/en/sivut/palveluvayla/tekninen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/technical-deployment/5-proceed-to-the-production-environment'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/hallinnollisen-kaeyttoeoenoton-vaiheet',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-2',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment/1-familiarise-and-plan'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-2',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment/2-select-a-security-server-solution'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment/3-join-the-test-environment'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-2',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment/4-test-in-the-test-environment'
  },
  {
    from:
      '/en/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment/administrative-deployment/5-proceed-to-the-production-environment'
  },
  {
    from: '/en/sivut/palveluvayla/kayttoonotto/vaiheet',
    to: 'https://kehittajille.suomi.fi/services/data-exchange-layer/deployment'
  },
  {
    from: '/en/sivut/palveluvayla/yllapito/huolehdi-yllapidosta',
    to: 'https://kehittajille.suomi.fi/services/data-exchange-layer/maintenance'
  },
  {
    from: '/en/sivut/palveluvayla/yllapito/hallinnollinen-yllapito',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/maintenance/administrative-maintenance'
  },
  {
    from: '/en/sivut/palveluvayla/yllapito/tekninen-yllapito',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/maintenance/technical-maintenance'
  },
  {
    from: '/en/sivut/palveluvayla/yllapito/versiopaivitykset',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/maintenance/version-updates'
  },
  {
    from: '/en/sivut/palveluvayla/tuki/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/services/data-exchange-layer/contact-us'
  },
  {
    from: '/en/tuki/artikkelit/66fbd233cdfb02001add3085',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/support-articles/certificates/automatically-renewed-certificates-in-the-data-exchange-layer'
  },
  {
    from: '/en/ajankohtaista/uutiset/65d5b1218d2de6001c68f0de',
    to:
      'https://kehittajille.suomi.fi/topical/the-suomi-fi-data-exchange-layers-central-servers-test-environment-fi-test-will-be-updated-to-x-road-version-7-5-1'
  },
  {
    from: '/en/tuki/artikkelit/591ac1e314bbb10001966f9c',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/support-articles/security-server/connecting-a-new-subsystem-to-a-security-server-and-deleting-a-subsystem-from-a-security-server'
  },
  {
    from: '/en/tuki/artikkelit/60a93cfa36bd5a04ed4c9f55',
    to:
      'https://kehittajille.suomi.fi/services/data-exchange-layer/support-articles/testing/filling-in-the-user-permit-application-for-data-exchange-layer'
  },
  {
    from: '/sv/sivut/palveluvayla/palvelukuvaus/palvelun-kuvaus',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/presentation-av-tjansten/tjanstebeskrivning'
  },
  {
    from: '/sv/sivut/palveluvayla/esittely',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/presentation-av-tjansten'
  },
  {
    from: '/sv/sivut/palveluvayla/tuki/usein-kysyttya',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/stodartiklar/vanliga-fragor-om-informationsleden'
  },
  {
    from: '/sv/sivut/palveluvayla/tuki/tukiartikkelit',
    to: 'https://kehittajille.suomi.fi/tjanster/informationsleden/stodartiklar'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/teknisen-kaeyttoeoenoton-vaiheet',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-1',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning/1-bekanta-och-planera'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-1',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning/2-valj-losning-for-anslutningsserver'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning/3-anslut-dig-till-testmiljon'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-1',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning/4-testa-i-testmiljon'
  },
  {
    from:
      '/sv/sivut/palveluvayla/tekninen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-1',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/teknisk-ibruktagning/5-overga-till-produktionsmiljon'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/hallinnollisen-kaeyttoeoenoton-vaiheet',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/1-tutustu-ja-suunnittele-2',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning/1-bekanta-dig-och-planera'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/2-valitse-liityntaepalvelinratkaisu-2',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning/2-valj-losning-for-anslutningsserver'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/3-liity-testiympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning/3-anslut-dig-till-testmiljon'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/4-testaa-testiympaeristoessae-2',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning/4-testa-i-testmiljon'
  },
  {
    from:
      '/sv/sivut/palveluvayla/hallinnollinen-kaeyttoeoenotto/5-siirry-tuotantoympaeristoeoen-2',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande/administrativ-ibruktagning/5-overga-till-produktionsmiljon'
  },
  {
    from: '/sv/sivut/palveluvayla/kayttoonotto/vaiheet',
    to: 'https://kehittajille.suomi.fi/tjanster/informationsleden/ibruktagande'
  },
  {
    from: '/sv/sivut/palveluvayla/yllapito/huolehdi-yllapidosta',
    to: 'https://kehittajille.suomi.fi/tjanster/informationsleden/underhallet'
  },
  {
    from: '/sv/sivut/palveluvayla/yllapito/hallinnollinen-yllapito',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/underhallet/administrativt-underhall'
  },
  {
    from: '/sv/sivut/palveluvayla/yllapito/tekninen-yllapito',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/underhallet/tekniskt-underhall'
  },
  {
    from: '/sv/sivut/palveluvayla/yllapito/versiopaivitykset',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/underhallet/versionsuppdateringar'
  },
  {
    from: '/sv/sivut/palveluvayla/tuki/ota-yhteytta',
    to: 'https://kehittajille.suomi.fi/tjanster/informationsleden/ta-kontakt'
  },
  {
    from: '/sv/tuki/artikkelit/66fbd233cdfb02001add3085',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/stodartiklar/certifikat/informationsledens-automatiskt-fornyande-certifikat'
  },
  {
    from: '/sv/ajankohtaista/uutiset/65d5b1218d2de6001c68f0de',
    to:
      'https://kehittajille.suomi.fi/aktuell/centralservermiljon-for-suomi-fi-informationsledens-testmiljo-fi-test-uppdateras-till-x-road-version-7-5-1'
  },
  {
    from: '/sv/tuki/artikkelit/591ac1e314bbb10001966f9c',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/stodartiklar/anslutsningsserver/att-lagga-till-ett-nytt-subsystem-till-anslutningsservern-och-ta-bort-det'
  },
  {
    from: '/sv/tuki/artikkelit/60a93cfa36bd5a04ed4c9f55',
    to:
      'https://kehittajille.suomi.fi/tjanster/informationsleden/stodartiklar/provning/ifyllande-av-ansokan-om-anvandningstillstand-till-informationsleden'
  },
  {
    from: '/fi/sivut/verkkopalvelu/kayttoonotto',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto'
  },
  {
    from: '/fi/tuki/artikkelit/5bfd8a1f3e247a312e8e1773',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/handlebars-templaten-kaytto'
  },
  {
    from: '/fi/tuki/artikkelit/5bfeb5d3070e9b003b3ea3a7',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/metaluokittelu'
  },
  {
    from: '/fi/tuki/artikkelit/5bfd9503edfc19289097f787',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/rajapintakyselyt-rekistereissa'
  },
  {
    from: '/fi/tuki/artikkelit/5bfda0a4a76213671204cc94',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/rekisterin-kuvailutiedot'
  },
  {
    from: '/fi/tuki/artikkelit/5bfd9a16558c7800457bc75f',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/rekisterin-testaus'
  },
  {
    from: '/fi/tuki/artikkelit/5bfd86443e247a312e8e1772',
    to:
      'https://kehittajille.suomi.fi/palvelut/verkkopalvelu/verkkopalvelu-rekisterit/verkkopalvelu-kayttoonotto/rekisterin-tietomalli'
  },
  {
    from: '/fi/sivut/laatutyokalut/palvelukuvaus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/laatutyokalut/palvelukuvaus'
  },
  {
    from: '/fi/sivut/laatutyokalut/esittely/*',
    to: 'https://kehittajille.suomi.fi/palvelut/laatutyokalut/palvelukuvaus'
  },
  {
    from: '/fi/sivut/laatutyokalut/kayttoonotto/kayttoonoton-vaiheet/*',
    to: 'https://kehittajille.suomi.fi/palvelut/laatutyokalut/kayttoonotto'
  },
  {
    from: '/fi/sivut/tunnistus/palvelukuvaus/palvelun-kuvaus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/tunnistus/palvelukuvaus'
  },
  {
    from: '/sv/sivut/tunnistus/palvelukuvaus/palvelun-kuvaus/*',
    to:
      'https://kehittajille.suomi.fi/tjanster/identifikation/beskrivning-av-tjansten'
  },
  {
    from: '/en/sivut/tunnistus/palvelukuvaus/palvelun-kuvaus/*',
    to:
      'https://kehittajille.suomi.fi/services/e-identification/service-description'
  },
  {
    from: '/fi/sivut/tunnistus/yleiskuvaus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/tunnistus/tekninen-kuvaus'
  },
  {
    from: '/fi/tuki/artikkelit/*',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/tuki/artikkelit/*',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/tuki/artikkelit/*',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/tuki/ukk/*',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/tuki/ukk/*',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/tuki/ukk/*',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/ajankohtaista/*',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/ajankohtaista/*',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/ajankohtaista/*',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/sivut/palveluhallinnasta/lainsaadanto/*',
    to:
      'https://kehittajille.suomi.fi/sivu/suomi-fi-palveluiden-ohjeet-ovat-nyt-kehittajille-sivustolla'
  },
  {
    from: '/sv/sivut/palveluhallinnasta/lainsaadanto/*',
    to:
      'https://kehittajille.suomi.fi/sidan/du-hittar-anvisningarna-for-suomi-fi-tjansterna-pa-webbplatsen-suomi-fi-for-tjansteutvecklare-fran-och-med-den-30-10-2024'
  },
  {
    from: '/en/sivut/palveluhallinnasta/lainsaadanto/*',
    to:
      'https://kehittajille.suomi.fi/page/you-can-find-the-instructions-for-suomi-fi-services-on-the-suomi-fi-for-service-developers-website-from-30-october-2024-onward'
  },
  {
    from: '/fi/sivut/viestit/palvelukuvaus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit/palvelukuvaus'
  },
  {
    from: '/fi/sivut/viestit/usein-kysyttya/*',
    to:
      'https://kehittajille.suomi.fi/palvelut/viestit/usein-kysytyt-kysymykset'
  },
  {
    from: '/fi/sivut/palvelutietovaranto/*',
    to: 'https://kehittajille.suomi.fi/palvelut/palvelutietovaranto'
  },
  {
    from: '/sv/sivut/palvelutietovaranto/*',
    to: 'https://kehittajille.suomi.fi/tjanster/servicedatalager'
  },
  {
    from: '/en/sivut/palvelutietovaranto/*',
    to: 'https://kehittajille.suomi.fi/services/servicecatalogue'
  },
  {
    from: '/fi/sivut/maksut/palvelukuvaus/palvelun-kuvaus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut/palvelukuvaus'
  },
  {
    from: '/sv/sivut/maksut/palvelukuvaus/palvelun-kuvaus/*',
    to:
      'https://kehittajille.suomi.fi/tjanster/betalningar/beskrivning-av-tjansten'
  },
  {
    from: '/fi/sivut/maksut/*',
    to: 'https://kehittajille.suomi.fi/palvelut/maksut'
  },
  {
    from: '/sv/sivut/maksut/*',
    to: 'https://kehittajille.suomi.fi/tjanster/betalningar'
  },
  {
    from: '/en/sivut/maksut/*',
    to: 'https://kehittajille.suomi.fi/services/payments'
  },
  {
    from: '/fi/sivut/kartat/*',
    to: 'https://kehittajille.suomi.fi/palvelut/kartat'
  },
  {
    from: '/sv/sivut/kartat/*',
    to: 'https://kehittajille.suomi.fi/tjanster/kartor'
  },
  {
    from: '/en/sivut/kartat/*',
    to: 'https://kehittajille.suomi.fi/services/maps'
  },
  {
    from: '/fi/sivut/palveluvayla/*',
    to: 'https://kehittajille.suomi.fi/palvelut/palveluvayla'
  },
  {
    from: '/sv/sivut/palveluvayla/*',
    to: 'https://kehittajille.suomi.fi/tjanster/informationsleden'
  },
  {
    from: '/en/sivut/palveluvayla/*',
    to: 'https://kehittajille.suomi.fi/services/data-exchange-layer'
  },
  {
    from: '/fi/sivut/viestit/*',
    to: 'https://kehittajille.suomi.fi/palvelut/viestit'
  },
  {
    from: '/sv/sivut/viestit/*',
    to: 'https://kehittajille.suomi.fi/tjanster/meddelanden'
  },
  {
    from: '/en/sivut/viestit/*',
    to: 'https://kehittajille.suomi.fi/services/messages'
  },
  {
    from: '/fi/sivut/tunnistus/*',
    to: 'https://kehittajille.suomi.fi/palvelut/tunnistus'
  },
  {
    from: '/sv/sivut/tunnistus/*',
    to: 'https://kehittajille.suomi.fi/tjanster/identifikation'
  },
  {
    from: '/en/sivut/tunnistus/*',
    to: 'https://kehittajille.suomi.fi/services/e-identification'
  },
  {
    from: '/fi/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/palvelut/laatutyokalut'
  },
  {
    from: '/sv/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/en/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  },
  {
    from: '/fi/sivut/valtuudet/*',
    to: 'https://kehittajille.suomi.fi/palvelut/valtuudet'
  },
  {
    from: '/sv/sivut/valtuudet/*',
    to: 'https://kehittajille.suomi.fi/tjanster/fullmakter'
  },
  {
    from: '/en/sivut/valtuudet/*',
    to: 'https://kehittajille.suomi.fi/services/e-authorizations'
  },
  {
    from: '/fi/sivut/verkkopalvelu/*',
    to: 'https://kehittajille.suomi.fi/palvelut/verkkopalvelu'
  },
  {
    from: '/sv/sivut/verkkopalvelu/*',
    to: 'https://kehittajille.suomi.fi/tjanster/webbtjanst'
  },
  {
    from: '/en/sivut/verkkopalvelu/*',
    to: 'https://kehittajille.suomi.fi/services/web-service'
  },
  {
    from: '/fi/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/palvelut/laatutyokalut'
  },
  {
    from: '/sv/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/tjanster/kvalitetsverktyg'
  },
  {
    from: '/en/sivut/laatutyokalut/*',
    to: 'https://kehittajille.suomi.fi/services/quality-tools'
  }
];
