import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Text, ExternalLink } from 'suomifi-ui-components';
import { userPermitOfSuomiServices, messagesLicense } from '../../../constants';

const Messages = ({ children, i18n, locale }) => (
  <>
    <Paragraph marginBottomSpacing="s">
      <Text>
        {i18n('intermediary.common.read')}{' '}
        <ExternalLink
          href={userPermitOfSuomiServices(locale)}
          labelNewWindow={i18n('general.links.opens_in_new_window')}
        >
          {i18n('intermediary.terms.suomifiTerms')}
        </ExternalLink>{' '}
        {i18n('intermediary.common.and')}{' '}
        <ExternalLink
          href={messagesLicense(locale)}
          labelNewWindow={i18n('general.links.opens_in_new_window')}
        >
          {i18n('intermediary.terms.messagesTerms')}
        </ExternalLink>
        {'.'}
      </Text>
    </Paragraph>
    {children}
    <ul className="mt-2">
      <li>{i18n('intermediary.terms.suomifiLicense')}</li>
      <li>{i18n('intermediary.terms.messagesLicense')}</li>
    </ul>
  </>
);
Messages.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.func,
  locale: PropTypes.string
};

export default Messages;
