import { getErrorMessage } from 'utils/errors';

const requestConstants = {
  credentials: 'same-origin',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'X-Requested-With': 'SEMAFetch',
    'Content-Type': 'application/json'
  }
};

export async function postData(payload) {
  return fetch('/api/proposal/send/formless', {
    method: 'POST',
    body: JSON.stringify(payload),
    ...requestConstants
  })
    .then(response => response.status)
    .then(status => {
      if (status !== 200) {
        throw new Error('Error saving data');
      } else {
        return true;
      }
    })
    .catch(error => {
      throw new Error(getErrorMessage(error?.message));
    });
}
