import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader/index';
import ApplicationsTable from '../components/ApplicationsTable';
import { Heading } from 'suomifi-ui-components';

export const ApplicationsListView = (
  {
    activeOrganizationId,
    organisation,
    loadForm,
    isFetching,
    allForms,
    sortingColumns,
    showIntermediaryApplications,
    filters,
    updateSorting,
    updateFilter,
    updateQuery,
    updateFormState,
    getForms,
    query
  },
  { intl }
) => {
  const i18n = id => intl.formatMessage({ id });

  return !isFetching ? (
    <section>
      <div className="row">
        <div className="col-md-24">
          <Heading variant="h2" className="borderless mb-2">
            {showIntermediaryApplications
              ? i18n('intermediary.intermediaryApplications')
              : i18n('intermediary.ownOrganisationApplications')}
          </Heading>
          <ApplicationsTable
            allForms={allForms}
            sortingColumns={sortingColumns}
            updateFilter={updateFilter}
            updateQuery={updateQuery}
            updateSorting={updateSorting}
            updateFormState={updateFormState}
            getForms={getForms}
            activeOrganizationId={activeOrganizationId}
            organisation={organisation}
            loadForm={loadForm}
            showIntermediaryApplications={showIntermediaryApplications}
            filters={filters}
            query={query}
          />
        </div>
      </div>
    </section>
  ) : (
    <Loader />
  );
};

ApplicationsListView.propTypes = {
  // Redux props
  activeOrganizationId: PropTypes.string,
  isFetching: PropTypes.bool,
  allForms: PropTypes.array,
  sortingColumns: PropTypes.object,
  organisation: PropTypes.object,
  // Action creators
  loadForm: PropTypes.func,
  updateSorting: PropTypes.func,
  showIntermediaryApplications: PropTypes.bool,
  filters: PropTypes.object,
  updateFilter: PropTypes.func,
  updateQuery: PropTypes.func,
  updateFormState: PropTypes.func,
  getForms: PropTypes.func,
  query: PropTypes.object
};

ApplicationsListView.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default ApplicationsListView;
