import React from 'react';
import { debounce } from 'lodash';
import { string, object } from 'yup';
import { format, parseISO } from 'date-fns';

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function getPropValue(sourceObject, dotNotationPath) {
  return dotNotationPath
    .split('.')
    .reduce((acc, cur) => acc && acc[cur], sourceObject);
}

export const getNested = (obj, ...args) => {
  return args.reduce((obj, level) => {
    if (isString(level) && level.includes('.')) {
      return getPropValue(obj, level);
    }

    return obj && obj[level];
  }, obj);
};

export const getStatus = (formik = { errors: {}, touched: {} }, ...args) => {
  const error = getNested(formik.errors, ...args);
  const touched = getNested(formik.touched, ...args);

  return touched && error ? 'error' : 'default';
};

export const getStatusText = (
  t,
  formik = { errors: {}, touched: {} },
  ...args
) => {
  const error = getNested(formik.errors, ...args);
  const touched = getNested(formik.touched, ...args);
  return touched && error ? t(error) : undefined;
};

export const getUserActiveOrganization = user => {
  const organizations = user.organizations || [];
  return organizations.find(org => org.id === user.activeOrganizationId) || {};
};

export const isActiveOrgIntermediary = user =>
  Boolean(getUserActiveOrganization(user)?.intermediary);

export function useDebouncedValidate({ values, validate, debounceTime = 200 }) {
  const debouncedFunction = React.useRef(
    debounce((validateFunc, data) => {
      return validateFunc(data);
    }, debounceTime)
  );

  const debounceValidate = React.useCallback(data => {
    return debouncedFunction.current(validate, data);
  }, []);

  React.useEffect(() => {
    debounceValidate(values);
  }, [values]);

  React.useEffect(() => {
    return () => {
      debouncedFunction.current.cancel();
    };
  }, []);
}

const isNumeric = num => !isNaN(num);

export const splitName = name =>
  (name || [])
    .split('.')
    .map(part => (isNumeric(part) ? parseFloat(part) : part));

const getTranslationPath = path => `intermediary.validation.${path}`;

export const userStr = (required, path, formTitle, email = false) => {
  let str = string()
    .default('')
    .max(300, 'intermediary.validation.max300')
    .nullable()
    .label(formTitle);

  if (required) {
    str = str.required(getTranslationPath(path));
  }

  if (email) {
    str = str.email(getTranslationPath('emailIsInvalid'));
  }

  return str;
};

export const hasNull = arr => arr.some(item => item === null);

export const getValue = val => (val === null ? 'null' : val.toString());

export const backupUserSchema = formTitle =>
  object().shape(
    {
      firstName: string()
        .default('')
        .when(['lastName', 'email'], {
          is: (a, b) => a !== '' || b !== '',
          then: schema =>
            schema
              .required('intermediary.validation.allOrNotAtAll')
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .label(formTitle)
        }),
      lastName: string()
        .default('')
        .when(['firstName', 'email'], {
          is: (a, b) => a !== '' || b !== '',
          then: schema =>
            schema
              .required('intermediary.validation.allOrNotAtAll')
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .label(formTitle)
        }),
      email: string()
        .default('')
        .email(getTranslationPath('emailIsInvalid'))
        .when(['firstName', 'lastName'], {
          is: (a, b) => a !== '' || b !== '',
          then: schema =>
            schema
              .required('intermediary.validation.allOrNotAtAll')
              .min(3, 'intermediary.validation.min3')
              .max(300, 'intermediary.validation.max300')
              .label(formTitle)
        }),
      phoneNumber: string().default('')
    },
    [
      ['firstName', 'lastName'],
      ['firstName', 'email'],
      ['lastName', 'email']
    ]
  );

export const formatDate = date => {
  let ret = date;
  // PAHA-1982
  // the field eAuthorization form formData.servicesData.service.implementationDate used to be just a free text field
  // and thus as one would expect it may contain some janky values, including text strings. As the bulk of the data
  // is inaccessible in deeply nested JSON stored in SAHA, best guess type scenarios have to suffice.
  //
  // the regex is the best guess for a d.M.yyyy or d.M.yy date, and then reverting to the expected value.
  // If all fails, show nothing.
  if (!/^\d{1,2}\.\d{1,2}\.(?:\d{2}|\d{4})\s*$/.test(date)) {
    try {
      ret = date ? format(new Date(parseISO(date)), 'd.M.yyyy') : undefined;
    } catch (e) {
      console.log(e);
      ret = undefined;
    }
  }
  return ret;
};

export const createBody = files => {
  const body = new FormData();
  Object.keys(files).forEach(key => {
    body.append('file', files[key]);
  });
  return body;
};

export const getStepStatus = (currentStep, step, index) => {
  if (index === currentStep) {
    return step.completed ? 'current-completed' : 'current';
  }

  return step.completed ? 'completed' : 'default';
};

export const hasValues = values => {
  return (
    values &&
    values.organizationName &&
    values.address &&
    values.postalCode &&
    values.postOffice
  );
};
