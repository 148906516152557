import { CALL_API } from 'store/middleware/api';
import { createAction as ca, createReducer } from 'utils/index';

const PX = 'OWN_APPLICATIONS_VIEW';
const FETCH_APPLICATIONS = `${PX}_FETCH_APPLICATIONS`;
const FETCH_APPLICATIONS_SUCCESS = `${PX}_FETCH_APPLICATIONS_SUCCESS`;
const FETCH_APPLICATIONS_ERROR = `${PX}_FETCH_APPLICATIONS_ERROR`;
const UPDATE_APPLICATION = `${PX}_UPDATE_APPLICATION`;
const UPDATE_APPLICATION_SUCCESS = `${PX}_UPDATE_APPLICATION_SUCCESS`;
const UPDATE_APPLICATION_ERROR = `${PX}_UPDATE_APPLICATION_ERROR`;
const FETCH_APPLICATION = `${PX}_FETCH_APPLICATION`;
const FETCH_APPLICATION_SUCCESS = `${PX}_FETCH_APPLICATION_SUCCESS`;
const FETCH_APPLICATION_ERROR = `${PX}_FETCH_APPLICATION_ERROR`;
const SET_INTERMEDIARY = `${PX}_SET_INTERMEDIARY`;
const UPDATE_FILTER = `${PX}_UPDATE_FILTER`;
const UPDATE_QUERY = `${PX}_UPDATE_QUERY`;
const UPDATE_SORTING = `${PX}_UPDATE_SORTING`;

export const getForms = orgId => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `proposal/list/${orgId}`,
    types: [
      FETCH_APPLICATIONS,
      FETCH_APPLICATIONS_SUCCESS,
      FETCH_APPLICATIONS_ERROR
    ]
  }
});
export const updateFormState = (
  guid,
  clientGuid,
  intermediaryGuid = null,
  newState
) => ({
  [CALL_API]: {
    method: 'put',
    endpoint: `proposal/state`,
    data: {
      newState,
      proposalGuid: guid,
      clientGuid,
      intermediaryGuid
    },
    types: [
      UPDATE_APPLICATION,
      UPDATE_APPLICATION_SUCCESS,
      UPDATE_APPLICATION_ERROR
    ]
  }
});

export const getApplication = (
  id,
  formState = 0,
  serviceType = 'eIdentification',
  proposalDecisionGuid = false
) => ({
  [CALL_API]: {
    method: 'get',
    endpoint: proposalDecisionGuid
      ? `proposal/find/${id}?formState=${formState}&serviceType=${serviceType}&proposalDecisionGuid=${proposalDecisionGuid}`
      : `proposal/find/${id}?formState=${formState}&serviceType=${serviceType}`,
    types: [
      FETCH_APPLICATION,
      FETCH_APPLICATION_SUCCESS,
      FETCH_APPLICATION_ERROR
    ]
  }
});

export const setIntermediaryRole = showIntermediaryApplications => dispatch => {
  dispatch(ca(SET_INTERMEDIARY)(showIntermediaryApplications));
};

const initialState = {
  showIntermediaryApplications: false,
  isFetching: false,
  forms: [],
  filters: {
    service: 'all',
    state: 'all'
  },
  query: {},
  error: null,
  application: {},
  sortingColumns: {
    6: {
      direction: 'desc',
      position: 0
    }
  }
};

const ACTION_HANDLERS = {
  [FETCH_APPLICATION]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [FETCH_APPLICATION_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isFetching: false,
      application: payload
    };
  },
  [FETCH_APPLICATION_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    application: null,
    error: payload
  }),
  [FETCH_APPLICATIONS]: (state, { payload }) => ({
    ...state,
    isFetching: true
  }),
  [FETCH_APPLICATIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isFetching: false,
      forms: Array.isArray(payload)
        ? payload.sort((a, b) => b.modifiedDate - a.modifiedDate) // Sort initially by modified date
        : []
    };
  },
  [FETCH_APPLICATIONS_ERROR]: (state, { payload }) => ({
    ...state,
    isFetching: false,
    forms: [],
    error: payload
  }),
  [SET_INTERMEDIARY]: (state, { payload }) => {
    return {
      ...state,
      showIntermediaryApplications: payload
    };
  },
  [UPDATE_FILTER]: (state, { payload }) => {
    const { filter, value } = payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        [filter]: value
      }
    };
  },
  [UPDATE_QUERY]: (state, { payload }) => {
    const { query } = payload;
    return {
      ...state,
      query
    };
  },
  [UPDATE_SORTING]: (state, { payload }) => ({
    ...state,
    ...payload
  })
};

export const updateFilter = (filter, value) => dispatch =>
  dispatch(ca(UPDATE_FILTER)({ filter, value }));

export const updateQuery = query => dispatch =>
  dispatch(ca(UPDATE_QUERY)({ query }));

export const updateSorting = value => dispatch =>
  dispatch(ca(UPDATE_SORTING)(value));

export default createReducer(ACTION_HANDLERS, initialState);
