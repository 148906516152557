import React from 'react';
import PropTypes from 'prop-types';
import { IconBox } from 'sema-ui-components';
import { pure } from 'recompose';
import cx from 'classnames';
import InlineSVG from 'svg-inline-react';
import { FormattedMessage } from 'react-intl';
import TranslatableHelmet from 'components/TranslatableHelmet/TranslatableHelmet';
import serviceBusIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/illustration/icon-palveluvayla.svg';
import identificationIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/illustration/icon-tunnistus.svg';
import qualitytoolsIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/illustration/icon-laatutyokalu.svg';
import paymentsIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/illustration/icon-pankkikortit.svg';
import webServiceIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/brand_serviceviews.svg';
import serviceCatalogIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/brand_ptv.svg';
import authorizationIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/brand_rova.svg';
import linkIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/icon-link.svg';
import mapsIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/brand_map.svg';
import messagesIcon from '!!svg-inline-loader!sema-ui-components/dist/components/icons/old/brand_messages.svg';
import styles from './HomeView.scss';
import { featureFlags } from 'constants';
import { Heading, Text } from 'suomifi-ui-components';

const HomeView = (props, context) => {
  const { locale, features } = props;
  const { intl } = context;
  const t = id => intl.formatMessage({ id });

  const renderEsuomiLink = url => {
    return (
      <div className={styles.esuomiLink}>
        <a href={`${url}`} target="_blank" rel="noopener noreferrer">
          {t('esuomi_link')}
        </a>
        <InlineSVG src={linkIcon} />
      </div>
    );
  };

  const renderIconBox = service => {
    const attrs = {
      title: t(`${service.title}`),
      description: t(`${service.description}`),
      path: !service.esuomi ? service.path : '',
      icon: service.icon,
      borderStyle: 'borderGray',
      buttonTitle: service.buttonTitle && t(`${service.buttonTitle}`),
      height: 'h310',
      button: service.esuomi && renderEsuomiLink(service.path)
    };

    return <IconBox {...attrs} />;
  };

  const directLinks = features && features[featureFlags.DIRECT_LINKS];

  return (
    <section>
      <TranslatableHelmet locale={locale} subTitle={t('home.title')} />
      <div className={cx('row v-offset-5', styles.base)}>
        <div className="col-sm-24 col-md-14">
          <Heading variant="h3" className="mb-2">
            <FormattedMessage
              id="suomi_fi_services_title"
              defaultMessage="Suomi.fi-palvelut"
            />
          </Heading>
          <Text variant="body">
            <FormattedMessage id="suomi_fi_services_promo" />
          </Text>
        </div>
      </div>

      <div className={cx('row v-offset-2', styles.boxes)}>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'service_information_pool_title',
            description: 'service_information_pool_description',
            path: directLinks
              ? `/${locale}/valitoimija/servicecatalogue/uusi`
              : `/${locale}/sivut/palvelutietovaranto/esittely`,
            icon: serviceCatalogIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'identification_title',
            description: 'identification_description',
            path: directLinks
              ? `/${locale}/valitoimija/eIdentification/uusi`
              : `/${locale}/sivut/tunnistus/esittely`,

            icon: identificationIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'transact_authorization_title',
            description: 'transact_authorization_description',
            path: directLinks
              ? `/${locale}/valitoimija/eauthorization/uusi`
              : `/${locale}/sivut/valtuudet/esittely`,
            icon: authorizationIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'messages_title',
            description: 'messages_description',
            path: directLinks
              ? `/${locale}/valitoimija/messages/uusi`
              : `/${locale}/sivut/viestit/esittely`,
            icon: messagesIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'maps_title',
            description: 'maps_description',
            path: `/${locale}/sivut/kartat/esittely`,
            icon: mapsIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'payments_title',
            description: 'payments_description',
            path: `/${locale}/sivut/maksut/esittely`,
            icon: paymentsIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'national_service_route_title',
            description: 'national_service_route_description',
            path: directLinks
              ? `/${locale}/valitoimija/dataexchangelayer/uusi`
              : `/${locale}/sivut/palveluvayla/esittely`,
            icon: serviceBusIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'web_service_title',
            description: 'web_service_description',
            path: `/${locale}/sivut/verkkopalvelu/esittely`,
            icon: webServiceIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6">
          {renderIconBox({
            title: 'qualitytools_title',
            description: 'qualitytools_description',
            path: `/${locale}/sivut/laatutyokalut/esittely`,
            icon: qualitytoolsIcon,
            buttonTitle: 'check_out_service'
          })}
        </div>
      </div>
    </section>
  );
};

HomeView.propTypes = {
  importantArticles: PropTypes.any,
  isFetchingArticles: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  features: PropTypes.object
};

HomeView.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default pure(HomeView);
