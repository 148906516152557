import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isValidCompanyId } from 'utils/validator';
import { Field } from 'formik';
import {
  Alert,
  Block,
  Button,
  Checkbox,
  LoadingSpinner,
  SearchInput
} from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  InputFields,
  OrganisationSelector,
  OwnOrganisationFields,
  ReadOnlyFields
} from './';
import {
  handleSetOrganisation,
  handleClearOrganisation,
  hasValues
} from '../../utils';
import Wrapper from './Wrapper';
import { formStates } from '../../constants';
import { formTitleIntermediary, formTitle } from './schema';

const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const StyledSearch = styled.div`
  margin-bottom: 1rem;
`;

const StyledOrganisationData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrganisationInfo = (
  {
    errorFetchingYTJData,
    formik,
    getYTJData,
    isFetchingYTJData,
    nextFormState,
    setErrorFetchingYTJData,
    ytjData
  },
  { intl }
) => {
  const [fetched, setFetched] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  const i18n = id => intl.formatMessage({ id });
  const isIntermediary =
    nextFormState === formStates.WAITING_CUSTOMER_TO_APPROVE;

  const [businessId, setBusinessId] = useState('');

  const formData = formik.values?.formData || {};

  useEffect(() => {
    if (ytjData) {
      if (ytjData.length > 1) {
        // If there's multiple organizations, set them as possible options
        const orgs = ytjData.map((org, index) => ({
          ...org,
          labelText:
            org.name +
            (org.organizationUnit ? ` - (${org.organizationUnit})` : ''),
          uniqueItemId: index
        }));

        setOrganisations(orgs);
      } else {
        // If there is only one organization, set it as the selected one
        handleSetOrganisation(ytjData[0], formik);
      }
    }
  }, [ytjData]);

  const fetchOrganizationData = async () => {
    if (!businessId.length) {
      return;
    }

    getYTJData(businessId);
  };

  const clearOrganisation = () => {
    setFetched(false);
    setBusinessId('');
    handleClearOrganisation(formik);
  };

  const handleSearch = () => {
    if (!isFetchingYTJData && isValidCompanyId(businessId)) {
      fetchOrganizationData();
    }
  };

  if (!isIntermediary) {
    return (
      <Wrapper
        formTitle={formTitle}
        formTitleIntermediary={formTitleIntermediary}
      >
        <OwnOrganisationFields />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      isIntermediary
      formTitle={formTitle}
      formTitleIntermediary={formTitleIntermediary}
    >
      {errorFetchingYTJData && (
        <Block>
          <Alert
            status="warning"
            closeText={i18n('intermediary.common.close')}
            onCloseButtonClick={() => setErrorFetchingYTJData(false)}
          >
            <FormattedMessage id="intermediary.common.fetchingInformationFailed" />
          </Alert>
        </Block>
      )}

      <Block>
        <StyledSearch>
          <Field
            as={SearchInput}
            labelText={i18n('intermediary.organisationInfo.customerYId')}
            hintText={i18n('intermediary.organisationInfo.fetchedFrom')}
            searchButtonLabel="Search"
            clearButtonLabel="Clear"
            onChange={value => setBusinessId(value.trim())}
            onBlur={formik.handleBlur}
            disabled={
              formData.customerOrganization?.noBusinessId || isFetchingYTJData
            }
            width="66.66%"
            value={businessId}
            status={
              isValidCompanyId(businessId)
                ? 'default'
                : businessId.length > 7
                ? 'error'
                : 'default'
            }
            statusText={
              businessId.length > 0 && !isValidCompanyId(businessId)
                ? i18n('intermediary.organisationInfo.invalidYId')
                : ''
            }
            searchButtonProps={{
              disabled:
                formData.customerOrganization?.noBusinessId ||
                isFetchingYTJData ||
                !isValidCompanyId(businessId)
            }}
            onSearch={handleSearch}
          />
        </StyledSearch>
        <Checkbox
          name="formData.customerOrganization.noBusinessId"
          checked={formData.customerOrganization?.noBusinessId}
          onClick={({ checkboxState }) => {
            formik.setFieldValue(
              'formData.customerOrganization.noBusinessId',
              checkboxState
            );
          }}
          disabled={
            fetched ||
            isFetchingYTJData ||
            hasValues(formData.customerOrganization)
          }
        >
          <FormattedMessage id="intermediary.organisationInfo.noFinnishYId" />
        </Checkbox>
      </Block>
      {organisations.length > 0 && (
        <OrganisationSelector
          {...{ i18n, organisations, handleSetOrganisation, formik }}
        />
      )}
      <Block>
        {formData.customerOrganization?.noBusinessId ? (
          <InputFields />
        ) : fetched || hasValues(formData.customerOrganization) ? (
          <StyledOrganisationData>
            <ReadOnlyFields />
            <div>
              <StyledButton
                type="button"
                onClick={clearOrganisation}
                variant="secondary"
                disabled={!hasValues(formData.customerOrganization)}
              >
                <FormattedMessage id={'intermediary.organisationInfo.reset'} />
              </StyledButton>
            </div>
          </StyledOrganisationData>
        ) : (
          isFetchingYTJData && (
            <LoadingSpinner
              status="loading"
              variant="normal"
              textAlign="right"
              text={i18n('intermediary.organisationInfo.loading')}
            />
          )
        )}
      </Block>
    </Wrapper>
  );
};

OrganisationInfo.contextTypes = {
  intl: PropTypes.object.isRequired
};

OrganisationInfo.propTypes = {
  errorFetchingYTJData: PropTypes.bool,
  formik: PropTypes.object,
  getYTJData: PropTypes.func,
  isFetchingYTJData: PropTypes.bool,
  nextFormState: PropTypes.number,
  proposalStyle: PropTypes.string,
  setErrorFetchingYTJData: PropTypes.func,
  state: PropTypes.object,
  ytjData: PropTypes.array
};

export default OrganisationInfo;
