import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Button, Link, Paragraph } from 'suomifi-ui-components';
import { FormattedMessage } from 'react-intl';
import { browserHistory } from 'react-router';
import styles from './FormView.scss';

const FormView = ({
  postData,
  sendSuccessNotification,
  sendErrorNotification,
  changeOrg,
  user
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendSuccess = () => {
    sendSuccessNotification(
      'enrollment.notifications.submitted.title',
      'enrollment.notifications.submitted.description'
    );
  };

  const sendError = () => {
    sendErrorNotification(
      'enrollment.notifications.error.title',
      'enrollment.notifications.error.description'
    );
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const ok = await postData();
      if (ok) {
        sendSuccess();
        // in the absence of an endpoint to update token, change org endpoint is used
        changeOrg(user.activeOrganizationId);
        browserHistory.push('/dashboard');
      }
    } catch (error) {
      sendError();
    }
  };

  return (
    <>
      <Heading className={styles.enrollment__heading} variant="h1">
        <FormattedMessage id="enrollment.mainHeading" />
      </Heading>
      <div className={styles.enrollment}>
        <Paragraph>
          <FormattedMessage id="enrollment.heading" />
        </Paragraph>
        <hr />
        <div className={styles.enrollment__buttonContainer}>
          <Link href="/">
            <Button variant="secondaryNoBorder" disabled={isSubmitting}>
              <FormattedMessage id="enrollment.cancel" />
            </Button>
          </Link>
          <Button
            className={styles.enrollment__button}
            onClick={handleSave}
            disabled={isSubmitting}
          >
            <FormattedMessage id="enrollment.continue" />
          </Button>
        </div>
      </div>
    </>
  );
};

FormView.propTypes = {
  postData: PropTypes.func.isRequired,
  sendSuccessNotification: PropTypes.func.isRequired,
  sendErrorNotification: PropTypes.func.isRequired,
  changeOrg: PropTypes.func.isRequired
};

export default FormView;
